<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="출고 기간"
            name="outDts"
            v-model="searchParam.outDts"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-material
            type="search"
            label="자재"
            name="materialCd"
            :plantCd="searchParam.plantCd"
            v-model="searchParam.materialCd">
          </c-material>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-equip
            label="대상설비"
            name="equipmentCd"
            v-model="searchParam.equipmentCd">
          </c-equip>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설비별 자재 출고 이력"
      :columns="grid.columns"
      :merge="grid.merge"
      :data="grid.data"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'outernal-manage',
  data() {
    return {
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      searchParam: {
        plantCd: null,
        outDts: [],
        materialCd: '',
        equipmentCd: '',
      },
      grid: {
        merge:  [
            { index: 0, colName: "equipmentCd" },
            { index: 1, colName: "equipmentCd" },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'materialName',
            field: 'materialName',
            label: '자재명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'outDt',
            field: 'outDt',
            label: '출고일',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'outStock',
            field: 'outStock',
            label: '출고량',
            type: 'cost',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'materialUnitName',
            field: 'materialUnitName',
            label: '단위',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.sp.materialout.status.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
